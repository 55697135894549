<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="10">
                    <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{$t("message.call_history")}}
                            </div>
                         
                            <div class="block-sarche ml-5 mml-0">
                                <div class="header__search">
                                    <el-select size="small" v-model="filterForm.number_of_data" :class="mode ? 'input__day' : 'input__night'" placeholder="Select">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            
                            <div class="block-sarche ml-5 mml-0">
                                <div class="header__search" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                                    <el-button v-loading="loadingButton" @click="fetchData()" size="small" icon="el-icon-search" round>{{$t("message.search")}}</el-button>
                                </div>
                            </div>
                            
                        </div>
                    </el-col>

                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="flex-style text-right">
                        <el-dropdown class="setting-cheek ml-2" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                            <el-button
                                class="padding_none"
                                size="small"
                                icon="el-icon-open"
                            ></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="(column, index) in columns"
                                    :key="index"
                                >
                                    <el-checkbox
                                        :checked="column.show"
                                        @change="column.show = !column.show"
                                        >{{ column.title }}</el-checkbox
                                    >
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered">
                    <thead>
                        <tr>
                            <th v-if="columns.caller_name.show">
                                {{ columns.caller_name.title }}
                            </th>

                            <th v-if="columns.caller.show">
                                {{ columns.caller.title }}
                            </th>
                            
                            <th v-if="columns.target_name.show">
                                {{ columns.target_name.title }}
                            </th>

                            <th v-if="columns.called_to.show">
                                {{ columns.called_to.title }}
                            </th>

                            <th v-if="columns.billsec.show">
                                {{ columns.billsec.title }}
                            </th>

                            <th v-if="columns.callStatus.show">
                                {{ columns.callStatus.title }}
                            </th>

                            <th v-if="columns.calldate.show">
                                {{ columns.calldate.title }}
                            </th>

                            <th v-if="columns.recordingfile.show && permissions.some(per => per.slug == 'callHistory.audiofile')">
                                {{ columns.recordingfile.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">

                            <th v-if="columns.caller_name.show">
                            
                            </th>

                            <th v-if="columns.caller.show">
                                <el-input
                                    :disabled="!(authUser.is_super_admin || (filterForm.dst.length < 1) )"
                                    clearable
                                    size="medium"
                                    type="number"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.src"
                                    :placeholder="columns.caller.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.target_name.show">
                            
                            </th>

                            <th v-if="columns.called_to.show">
                                <el-input
                                    :disabled="!(authUser.is_super_admin || (filterForm.src.length < 1))"
                                    clearable
                                    type="number"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    size="medium"
                                    v-model="filterForm.dst"
                                    :placeholder="columns.called_to.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.billsec.show">
                         
                            </th>

                            <th v-if="columns.callStatus.show">
                                <el-select :class="mode ? 'filter__day' : 'filter__night'" clearable size="medium" v-model="filterForm.disposition" :placeholder="columns.callStatus.title">
                                    <el-option
                                        v-for="item in statusOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </th>

                            <th v-if="columns.calldate.show">
                                <el-date-picker
                                    v-model="filterForm.calldate"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    type="date"
                                    size="medium"
                                    :placeholder="columns.calldate.title"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </th>

                            <th v-if="columns.recordingfile.show && permissions.some(per => per.slug == 'callHistory.audiofile')">
                               
                            </th>

                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody" v-loading="loadingData">
                        <tr
                            v-for="(call, index) in list"
                            :key="'call'+index"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.caller_name.show" style="text-align: center; vertical-align: middle;">
                                {{ checkContact(call) }}
                            </td>

                            <td v-if="columns.caller.show" style="text-align: center; vertical-align: middle;">
                                    <span style="display: block;">
                                        {{ call.cnum }}
                                    </span> 
                                    <span>
                                        {{ call.cnum !== call.src ? '( ' + call.src + ' )' : '' }}
                                    </span>                                
                            </td> 
                            
                            <td v-if="columns.caller_name.show" style="text-align: center; vertical-align: middle;">
                                <span v-html="removePrefixFromNumber(call, true)"></span> 
                            </td>

                            <td v-if="columns.called_to.show" style="text-align: center; vertical-align: middle;">
                                <span v-html="removePrefixFromNumber(call, false)"></span>
                            </td>

                            <td v-if="columns.billsec.show" style="text-align: center; vertical-align: middle;">
                                {{ calculateCallDuration(call.billsec) }}
                            </td>

                            <td v-if="columns.callStatus.show" style="text-align: center; vertical-align: middle;">
                                <div v-if="call.disposition === 'ANSWERED'" class="coo1">{{ $t('message.' + call.disposition) }}</div>
                                <div v-if="call.disposition === 'NO ANSWER' || call.disposition === 'BUSY'" class="coo2">{{ $t('message.' + call.disposition) }}</div>
                            </td>

                            <td v-if="columns.calldate.show" style="text-align: center; vertical-align: middle;">
                                {{ call.calldate }}
                            </td>

                            <td v-if="columns.recordingfile.show  && permissions.some(per => per.slug == 'callHistory.audiofile')">
                                <div v-if="call.recordingfile">
                                    <audio id="audioFromExternal" controls :src="listenToTheAudio(call)"></audio>
                                </div>
                                <span v-if="!call.recordingfile">  {{$t("message.no_audio_file")}} </span>
                            </td>
                        </tr>
                    </transition-group>
                </table>
            </div>

            <el-drawer
                class="right-modal"
                :with-header="false"
                :append-to-body="true"
                :visible.sync="appModal"
                size="80%"
            >
                <CrmCreate />
            </el-drawer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import callHistory from "@/utils/mixins/views/callHistory";

export default {
    name: "callHistories",
    mixins: [callHistory],
    components: {},

    data() {
        return {
            loadingButton: false,
            loadingData: false,
            appModal: false,
            sarcheBlocks: "",
            audioSrc: "",
            sipAccountList: [],
            filterForm: {
                number_of_data: 30,
                src: '',
                dst: '',
                disposition: '',
                calldate: '',
            },
            statusOptions : [
                {
                    value: 'ANSWERED',
                    label:  this.$t("message.ANSWERED")
                },
                {
                    value: 'NO ANSWER',
                    label: this.$t("message.NO ANSWER")
                },
                {
                    value: 'BUSY',
                    label: this.$t("message.BUSY")
                },
            ],
            options: [
                {
                    value: 10,
                    label: 10
                },
                {
                    value: 30,
                    label: 30
                },
                {
                    value: 50,
                    label: 50
                },
                {
                    value: 100,
                    label: 100
                },
                {
                    value: 150,
                    label: 150
                },
                {
                    value: 200,
                    label: 200
                },
            ],
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapGetters({
            list: "callHistories/list",
            columns: "callHistories/columns",
            authUser: "auth/user",
            phoneContacts: "phoneContacts/list",
            permissions: "auth/permissions",
            mode: "MODE",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
        }),
    },
    watch: {
        serverSipAccountList (next, prev) {
            if(this.serverSipAccountList.length > 0){
                setTimeout(() => {
                    this.fetchData();
                }, 100);
            }
        }
    },

     methods: {
        ...mapActions({
            updateList: "callHistories/index",
            updateContactList: "phoneContacts/index",
        }),
        
        fetchData() {
            this.updateContactList();
            this.sipAccountList = JSON.parse(JSON.stringify(this.serverSipAccountList));
            if(this.sipAccountList){
                this.loadingButton = true;
                this.loadingData = true;
                this.updateList(this.filterForm)
                    .then(res => {
                        this.loadingButton = false;
                        this.loadingData = false;
                    })
                    .catch(err => {
                        this.loadingButton = false;
                        this.loadingData = false;
                    });
            }
        },
        
        
    },
    
};
</script>

